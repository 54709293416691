<template>
  <b-col cols="12">
    <div class="plays-container p-3">
      <p class="plays mb-0">{{ plays_month.amount }}</p>
      <p class="this-month mb-0">{{ plays_month.text }}</p>
      <p class="rank mb-0">{{ plays_month.position }}</p>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "MonthlyPlays",
  props: ["plays_month"],
};
</script>

<style scoped>
.plays-container {
  border: 1px solid white;
  height: 156px;
}

.plays {
  font-size: 60px;
  font-weight: 200;
  line-height: 60px;
}

.this-month {
  font-size: 24px;
  font-weight: 300;
  line-height: 35px;
}

.rank {
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
}

@media (min-width: 576px) {
  .plays-container {
    border: 1px solid white;
    height: 156px;
  }

  .plays {
    font-size: 60px;
    font-weight: 200;
    line-height: 60px;
  }

  .this-month {
    font-size: 24px;
    font-weight: 300;
    line-height: 35px;
  }

  .rank {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .plays-container {
    border: 1px solid white;
    height: 156px;
  }

  .plays {
    font-size: 60px;
    font-weight: 200;
    line-height: 60px;
  }

  .this-month {
    font-size: 24px;
    font-weight: 300;
    line-height: 35px;
  }

  .rank {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
  }
}

@media (min-width: 992px) {
  .plays-container {
    border: 1px solid white;
    height: 156px;
  }

  .plays {
    font-size: 60px;
    font-weight: 200;
    line-height: 60px;
  }

  .this-month {
    font-size: 24px;
    font-weight: 300;
    line-height: 35px;
  }

  .rank {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
  }
}

@media (min-width: 1200px) {
  .plays-container {
    border: 1px solid white;
    height: 156px;
  }

  .plays {
    font-size: 60px;
    font-weight: 200;
    line-height: 60px;
  }

  .this-month {
    font-size: 24px;
    font-weight: 300;
    line-height: 35px;
  }

  .rank {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
  }
}
</style>
