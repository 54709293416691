<template>
  <b-col cols="12">
    <div class="being-played-container py-3 px-4">
      <b-row>
        <h5 class="ml-2">Whats being played?</h5>

        <div v-if="being_played.songs.length > 0" class="w-100">
          <b-col cols="12" class=" pl-2">
            <ol class="mb-1 px-0">
              <div
                v-for="(song, index) in being_played.songs"
                :key="index"
                class="list-item mb-3"
              >
                <img :src="song.photo" class="mr-4 float-left" height="25px" />
                <li class="song-name align-self-center" style="">
                  {{ song.title }}
                </li>
              </div>
            </ol>
          </b-col>
        </div>

        <div v-else class="w-100">
          <div class="list-item mt-2">
            <div class="mr-3 ml-2 float-left c-checkpoint-item"></div>
            <span class="text-muted c-label-nothing-to-show"
              >Nothing to show</span
            >
          </div>
        </div>
      </b-row>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "BeingPlayed",
  props: ["being_played"],
};
</script>

<style scoped>
.being-played-container {
  border: 1px solid white;
  height: 263px;
}

.being-played-container h5 {
  font-size: 18px;
  font-weight: 300;
  line-height: 36px;
}

.list-item {
  display: flex;
  margin-bottom: 3px;
}

.song-name {
  font-size: 12px;
  font-weight: 300;
  /* line-height: 14px; */
}

.c-label-nothing-to-show {
  font-size: 16px;
  font-weight: 300;
  line-height: 29px;
}

.c-checkpoint-item {
  width: 35px;
  height: 35px;
  border: 1px solid #9B9B9B;
  position: relative;
  top: -3px;
  border-radius: 90%;
}

@media (min-width: 576px) {
  .being-played-container {
    border: 1px solid white;
    height: 263px;
  }

  .being-played-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }

  .list-item {
    display: flex;
    margin-bottom: 3px;
  }

  .song-name {
    font-size: 12px;
    font-weight: 300;
    /* line-height: 14px; */
  }
}

@media (min-width: 768px) {
  .being-played-container {
    border: 1px solid white;
    height: 263px;
  }

  .being-played-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }

  .list-item {
    display: flex;
    margin-bottom: 3px;
  }

  .song-name {
    font-size: 12px;
    font-weight: 300;
    /* line-height: 14px; */
  }
}

@media (min-width: 992px) {
  .being-played-container {
    border: 1px solid white;
    height: 263px;
  }

  .being-played-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }

  .list-item {
    display: flex;
    margin-bottom: 3px;
  }

  .song-name {
    font-size: 12px;
    font-weight: 300;
    /* line-height: 14px; */
  }
}

@media (min-width: 1200px) {
  .being-played-container {
    border: 1px solid white;
    height: 263px;
  }

  .being-played-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }

  .list-item {
    display: flex;
    margin-bottom: 3px;
  }

  .song-name {
    font-size: 12px;
    font-weight: 300;
    /* line-height: 14px; */
  }
}

@media (max-width: 992px) {
  .being-played-container {
    height: auto;
  }
}
</style>
