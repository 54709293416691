<template>
  <div>
    <!-- MODAL -->

    <b-modal
      id="verify-account-info-modal"
      ref="verify-account-info-modal"
      size="lg"
      content-class="bg-dark text-light  "
      centered
      hide-footer
      header-close-variant="light"
    >
      <template #modal-header="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="float-left">
              <img
                src="../../../assets/trackd-icon-colours.svg"
                class="mb-4 mx-auto text-left mt-2"
                height="25"
                alt="Logo"
              />
            </b-col>

            <b-col class="float-right">
              <b-button
                size="sm"
                variant="link"
                class="float-right"
                @click="close()"
              >
                <img
                  src="../../../assets/section-close.svg"
                  class="mx-auto text-right float-right mt-2"
                  height="20"
                  alt="Close"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <b-row>

        <b-col col class="song-profile-des">
          <p class="font-weight-light mb-1 mt-2">Verify Account</p>
        </b-col>

        <b-col class="mb-3" cols="10" offset="1" sm="8" offset-sm="2">

          <p class="text-light font-weight-normal">Heading</p>

          <p class="text-light font-weight-light">abc abc abd abc  djhd sdjd jhd</p>

          <p class="text-light font-weight-normal">Heading</p>

          <p class="text-light font-weight-light">abc abc abd abc  djhd sdjd jhd</p>

          <p class="text-light font-weight-normal">Heading</p>

          <p class="text-light font-weight-light">abc abc abd abc  djhd sdjd jhd</p>

        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>

<script>
import { mapGetters } from 'vuex';

export default {
  
  name: "VerifyAccountInfo",
  data() {
    return {
        stripe: self.stripe = Stripe(process.env.VUE_APP_STRIPE_TCLLC_KEY), 
        chipin_slide: false,
    };
  },
  methods: {
    ChoosePlan() {
        
        var self = this;

        this.subscribe({price_id: process.env.VUE_APP_STRIPE_UPGRADE_PRICE_ID})
        .then(res => {

            return self.stripe.redirectToCheckout({ 
                sessionId: res.checkout_session_id,
            })

        })
        .catch(error => {
          error
            this.name = 'Sorry something went wrong!';
        });

    }
  },
  computed: {
    ...mapGetters(['user_image'])
    
  },
  mounted: function () {
    
  },
};
</script>

<style scoped>
#chipin-form {
  width: 100%;
  margin-top: 10px;
}

.modal-content {
  width: auto !important;
  margin: auto;
}

#chipin-form > h1 {
  font-weight: 900 !important;
  font-size: 36px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > p {
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.light-grey-border {
  border: 2px solid #9b9b9b;
}

.red-text {
  color: #ff0000;
}

.dark-text {
  color: #595959;
}

.super-small {
  font-size: 0.7em;
  color: #595959;
}

.rounded-btn {
  border-radius: 10px;
}

.line-height {
  line-height: 1.45em;
}

.included-info {
  display: inline-flex;
  align-items: center;
  float: left;
}

.song-profile-des > p {
  font-weight: 200 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > h5 {
  font-weight: 500 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > span {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 24px;
  display: block;
  margin-top: 20px;
}

.months-btn {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.months-btn > button {
  border-radius: 7px;
  font-family: "SFPro";
}

.month-one {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background: white;
  color: black;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  width: 50%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin-right: -5px;
  z-index: 2;
}

.month-twelve {
  background: black;
  color: white;
  background: rgba(118, 118, 128, 0.12);
  border: 1px solid #ffffff;
  border-left: none;
  width: 50%;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.subscription {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.select-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
}

.show-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
  border: 1px solid #00AFDC;
  background-color: transparent;
}

.choose-text {
    font-size: 18px;
    font-weight: 700;
}

</style>
