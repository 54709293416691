<template>
  <b-col cols="12">
    <div class="fan-container p-3">
      <b-row>
        <b-col cols="12">
          <h5 class="ml-2">Where are my Fans?</h5>
        </b-col>

        <b-col cols="12">
          <ol class="mb-1 mt-xl-1 pl-2">
            <div v-if="fan_stats.fans.length > 0" class="w-100">
              <div
                v-for="(fan, index) in fan_stats.fans"
                :key="index"
                class="list-item"
              >
                <img
                  :src="fan.flag_image"
                  width="35"
                  height="21"
                  class="mr-4 float-left"
                />
                <li class="location" style="">{{ fan.name }}</li>
              </div>
            </div>

            <div v-else>
              <div class="list-item">
                <div class="mr-3 float-left c-checkpoint-item"></div>
                <span class="text-muted c-label-nothing-to-show"
                  >Nothing to show</span
                >
              </div>
            </div>
          </ol>
        </b-col>
      </b-row>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "FanLocation",
  props: ["fan_stats"],
};
</script>

<style scoped>
.fan-container {
  border: 1px solid white;
  height: 246px;
}

.fan-container h5 {
  font-size: 18px;
  font-weight: 300;
  line-height: 36px;
}

.location {
  font-size: 12px;
  font-weight: 300;
}

.list-item {
  display: flex;
  margin-bottom: 12px;
}

.c-label-nothing-to-show {
  font-size: 16px;
  font-weight: 300;
  line-height: 29px;
}

.c-checkpoint-item {
  width: 35px;
  height: 22px;
  border: 1px solid #9B9B9B;
  position: relative;
  top: 3px;
  
}

@media (min-width: 576px) {
  .fan-container {
    border: 1px solid white;
    height: 246px;
  }

  .fan-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }

  .location {
    font-size: 12px;
    font-weight: 300;
  }

  .list-item {
    display: flex;
    margin-bottom: 12px;
  }
}

@media (min-width: 768px) {
  .fan-container {
    border: 1px solid white;
    height: 246px;
  }

  .fan-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }

  .location {
    font-size: 12px;
    font-weight: 300;
  }

  .list-item {
    display: flex;
    margin-bottom: 12px;
  }
}

@media (min-width: 992px) {
  .fan-container {
    border: 1px solid white;
    height: 246px;
  }

  .fan-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }

  .location {
    font-size: 12px;
    font-weight: 300;
  }

  .list-item {
    display: flex;
    margin-bottom: 12px;
  }
}

@media (min-width: 1200px) {
  .fan-container {
    border: 1px solid white;
    height: 246px;
  }

  .fan-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }

  .location {
    font-size: 12px;
    font-weight: 300;
  }

  .list-item {
    display: flex;
    margin-bottom: 12px;
  }
}

@media (max-width: 992px) {
  .fan-container {
    height: auto;
  }
}
</style>
