<template>
  <b-col cols="12">
    <div class="earnings-container p-3">
      <p class="amount mb-0">{{ chipin.amount }}</p>
      <p class="month mb-0">{{ chipin.period }}</p>
      <p class="fans mb-0"><router-link class="fans mb-0" :to="{name: 'ChipInSubscriptions'}">{{ chipin.text }}</router-link></p>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "MonthlyEarnings",
  props: ["chipin"],
};
</script>

<style scoped>
.earnings-container {
  border: 1px solid #f2b471;
  height: 165px;
}

.amount {
  font-size: 60px;
  font-weight: 200;
  line-height: 60px;
}

.month {
  font-size: 24px;
  font-weight: 300;
  line-height: 35px;
}

.fans {
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  color: #f2b471;
}

@media (min-width: 576px) {
  .earnings-container {
    border: 1px solid #f2b471;
    height: 165px;
  }

  .amount {
    font-size: 60px;
    font-weight: 200;
    line-height: 60px;
  }

  .month {
    font-size: 24px;
    font-weight: 300;
    line-height: 35px;
  }

  .fans {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    color: #f2b471;
  }
}

@media (min-width: 768px) {
  .earnings-container {
    border: 1px solid #f2b471;
    height: 165px;
  }

  .amount {
    font-size: 60px;
    font-weight: 200;
    line-height: 60px;
  }

  .month {
    font-size: 24px;
    font-weight: 300;
    line-height: 35px;
  }

  .fans {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    color: #f2b471;
  }
}

@media (min-width: 992px) {
  .earnings-container {
    border: 1px solid #f2b471;
    height: 165px;
  }

  .amount {
    font-size: 60px;
    font-weight: 200;
    line-height: 60px;
  }

  .month {
    font-size: 24px;
    font-weight: 300;
    line-height: 35px;
  }

  .fans {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    color: #f2b471;
  }
}

@media (min-width: 1200px) {
  .earnings-container {
    border: 1px solid #f2b471;
    height: 165px;
  }

  .amount {
    font-size: 60px;
    font-weight: 200;
    line-height: 60px;
  }

  .month {
    font-size: 24px;
    font-weight: 300;
    line-height: 35px;
  }

  .fans {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    color: #f2b471;
  }
}

@media (max-width: 576px) {
  .earnings-container {
    width: auto;
    height: auto;
    padding-bottom: 25px;
  }
}
</style>
