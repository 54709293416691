<template>
  <b-modal
    ref="verify-account-modal"
    id="verify-account-modal"
    size="lg"
    centered
    hide-footer
    title-sr-only
    no-close-on-backdrop
    no-close-on-esc
    content-class="bg-dark text-light text-center"
    header-close-variant="light"
    title="Verify Account"
    v-model="showModal"
    modal-class="onboarding-modal"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">
          <!-- Required for equal spacing -->
          <b-col></b-col>

          <b-col cols="8">
            <img
              src="../../../assets/trackd-icon-colours.svg"
              class="mb-4 mx-auto text-center mt-2"
              height="35"
              alt="Logo"
            />
          </b-col>

          <b-col class="float-right">
            
            <b-button
              size="sm"
              variant="link"
              class="float-right"
              @click="close()"
            >
              <img
                src="../../../assets/section-close.svg"
                class="mx-auto text-right float-right mt-2"
                height="23"
                alt="Close"
              />
            </b-button>
            
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-row>
      <b-col cols="10" offset="1" sm="8" offset-sm="2" md="8" offset-md="2">

        <h2 class="text-left text-light text-title text-center">Verify your account</h2>

        <p class="text-light text-left text-center">
          Verify your account via email.
        </p>

        <b-card class="my-2 pb-2 shadow-sm bg-dark text-left text-center" no-body>
          <b-card-body>
            <div v-if="success">
              <p class="font-weight-light text-success">
                Look out for a email from us.
              </p>
            </div>
            <div v-if="error">
              <p class="font-weight-light text-danger">
                There was a problem. please try again.
              </p>
            </div>

              <b-button
                size="lg"
                @click="sendVerificationEmail()"
                variant="info"
                class="my-5 px-4 next-btn"
                >Send verification email</b-button
              >
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "VerifyAccount",
  data() {
    return {
      error: false,
      success: false,
      showModal: false,
    };
  },
  methods: {
    ...mapActions(["send_verfication_email"]),
    sendVerificationEmail() {

      this.send_verfication_email()
      .then((res) => {
          res
          this.success = true
      })
      .catch((error) => {
          error
          this.success = false
          this.error = true;
          this.api_error_msg = "There was a problem sending the email. Please try again."
      });

    }
  }
}

</script>

<style>
.card-body {
  padding: 0 !important;
}

.header-spacing {
  margin-top: 120px !important;
}

.text-small {
  font-size: 0.3em !important;
}

.badge-pill {
  padding: 0.001em;
}

.badge {
  padding: 0 !important;
}
</style>
