<template>
  
    <b-col cols="12" >
      <div class="account-setup-container p-3">
        <h5>Account Setup Steps</h5>

        <b-row class="mt-3 step">
          <b-col class="pl-0 pr-1">
            <span style="cursor:pointer;" @click="verify_account()">
              <img
                v-if="account_steps.verified_account === 'complete'"
                src="../../assets/dashboard/complete.svg"
                class="mx-3"
              />
              <img
                v-if="account_steps.verified_account === 'in_complete'"
                src="../../assets/dashboard/incomplete.svg"
                class="mx-3"
              />
              <img
                v-if="account_steps.verified_account === 'in_progress'"
                src="../../assets/dashboard/in-progress.svg"
                class="mx-3"
              />
              Verify Account
              <!--<img
            v-on:click="$bvModal.show('verify-account-info-modal')"
            src="../../assets/dashboard/about.svg"
            class="ml-3"
          />-->
              <img
                src="../../assets/arrow-right.svg"
                class="float-right arrow-account-steps ml-2"
                alt="Right arrow"
              />
            </span>
          </b-col>
        </b-row>

        <b-row class="step">
          <b-col class="pl-0 pr-1">
            <router-link
              :to="{ name: 'EditProfile' }"
              class="text-light font-weight-light"
            >
              <img
                v-if="account_steps.artist_profile === 'complete'"
                src="../../assets/dashboard/complete.svg"
                class="mx-3"
              />
              <img
                v-if="account_steps.artist_profile === 'in_complete'"
                src="../../assets/dashboard/incomplete.svg"
                class="mx-3"
              />
              <img
                v-if="account_steps.artist_profile === 'in_progress'"
                src="../../assets/dashboard/in-progress.svg"
                class="mx-3"
              />
              Create Artist Profile
              <img
                src="../../assets/arrow-right.svg"
                class="float-right arrow-account-steps ml-2"
                alt="Right arrow"
              />
            </router-link>
          </b-col>
        </b-row>

        <b-row class="step">
          <b-col class="pl-0 pr-1">
            <router-link
              :to="{ name: 'Upload' }"
              class="text-light font-weight-light"
            >
              <img
                v-if="account_steps.uploaded_song === 'complete'"
                src="../../assets/dashboard/complete.svg"
                class="mx-3"
              />
              <img
                v-if="account_steps.uploaded_song === 'in_complete'"
                src="../../assets/dashboard/incomplete.svg"
                class="mx-3"
              />
              <img
                v-if="account_steps.uploaded_song === 'in_progress'"
                src="../../assets/dashboard/in-progress.svg"
                class="mx-3"
              />
              Upload First Song
              <img
                src="../../assets/arrow-right.svg"
                class="float-right arrow-account-steps ml-2"
                alt="Right arrow"
              />
            </router-link>
          </b-col>
        </b-row>

        <b-row class="step">
          <b-col class="pl-0 pr-1">
            <img
              v-if="account_steps.setup_chipin === 'complete'"
              src="../../assets/dashboard/complete.svg"
              class="mx-3"
            />
            <img
              v-if="account_steps.setup_chipin === 'in_complete'"
              src="../../assets/dashboard/incomplete.svg"
              class="mx-3"
            />
            <img
              v-if="account_steps.setup_chipin === 'in_progress'"
              src="../../assets/dashboard/in-progress.svg"
              class="mx-3"
            />
            <span style="cursor:pointer;" @click="setup_chipin()">
              Setup ChipIn or ChipIn+</span
            >
            <img
              src="../../assets/arrow-right.svg"
              class="float-right arrow-account-steps ml-2"
              alt="Right arrow"
            />
          </b-col>
        </b-row>
      </div>
    <VerifyAccountInfo></VerifyAccountInfo>
    <VerifyAccount></VerifyAccount>
    </b-col>

  
</template>

<script>
import { mapGetters } from "vuex";
import VerifyAccountInfo from "./modal/VerifyAccountInfo.vue";
import VerifyAccount from "./modal/VerifyAccount.vue";

export default {
  name: "AccountSetupSteps",
  props: ["account_steps"],
  components: {
    VerifyAccount,
    VerifyAccountInfo,
  },
  methods: {
    ...mapGetters(["isChipInPlus"]),
    verify_account() {
      if (this.account_steps.verified_account !== "complete") {
        this.$bvModal.show("verify-account-modal");
      }
    },
    setup_chipin() {
      if (this.account_steps.setup_chipin === "complete") {
        this.$router.push({name: 'ChipInAccount'})
      }

      if (
        this.account_steps.setup_chipin !== "complete" &&
        this.account_steps.verified_account !== "in_progress"
      ) {
        this.$router.push({name: 'SetupChipIn'})
      }
    },
  },
};
</script>

<style scoped>
.arrow-account-steps {
  position: relative;
  top: 7px;
}

.account-setup-container {
  border: 1px solid #e76e66;
  height: 240px;
}

.account-setup-container h5 {
  font-size: 18px;
  font-weight: 300;
  line-height: 36px;
  text-transform: uppercase;
}

.step {
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
}

@media (min-width: 576px) {
  .account-setup-container {
    border: 1px solid #e76e66;
    height: 240px;
  }

  .account-setup-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
  }

  .step {
    font-size: 18px;
    font-weight: 300;
    line-height: 40px;
  }
}

@media (min-width: 768px) {
  .account-setup-container {
    border: 1px solid #e76e66;
    height: 240px;
  }

  .account-setup-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
  }

  .step {
    font-size: 18px;
    font-weight: 300;
    line-height: 40px;
  }
}

@media (min-width: 992px) {
  .account-setup-container {
    border: 1px solid #e76e66;
    height: 240px;
  }

  .account-setup-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
  }

  .step {
    font-size: 18px;
    font-weight: 300;
    line-height: 40px;
  }
}

@media (min-width: 992px) {
  .account-setup-container {
    border: 1px solid #e76e66;
    height: 240px;
  }

  .account-setup-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
  }

  .step {
    font-size: 18px;
    font-weight: 300;
    line-height: 40px;
  }
}

@media (min-width: 1200px) {
  .account-setup-container {
    border: 1px solid #e76e66;
    height: 240px;
  }

  .account-setup-container h5 {
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
    text-transform: uppercase;
  }

  .step {
    font-size: 18px;
    font-weight: 300;
    line-height: 40px;
  }
}

@media (max-width: 576px) {
  .account-setup-container {
    width: auto;
    height: auto;
  }
}
</style>
