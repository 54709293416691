<template>
  <b-col cols="12">
    <b-row class="px-0 mt-3 mt-md-0 c-artist">
      <b-col cols="4">
        <SettingsImageInput
          v-model="get_user_image"
          :file="get_user_image"
          :type="`photo`"
          @change="send_file"
          :shape="`rounddark`"
          class="user-image"
        ></SettingsImageInput>
      </b-col>

      <b-col class="pt-0">
        <h4
          class="
                username
                text-light
                font-weight-light
                mb-0 mb-md-2
                pl-3
            "
        >
          {{ username }}
        </h4>
        <h5 class="location pl-3 text-light font-weight-lighter mr-2">
          {{ location }}
        </h5>
      </b-col>
    </b-row>

    <b-row class="mt-3 menu-text">
      <!-- Section Heading -->
      <b-col
        cols="12"
        class="text-uppercase text-light font-weight-light mt-3 heading"
      >
        Account
        <hr class="bg-light" />
      </b-col>

      <!-- Section link -->
      <b-col cols="12" class="text-light font-weight-light">
        <router-link
          :to="{ name: 'EditAccountSettings' }"
          class="text-light d-block font-weight-light"
        >
          Account Settings

          <img
            src="../../assets/arrow-right.svg"
            class="float-right arrow ml-2"
            alt="Right arrow"
          />
          <span class="float-right">Edit</span>
        </router-link>
        <hr class="bg-light" />
      </b-col>

      <b-col cols="12" class="text-light font-weight-light">
        <router-link
          :to="{ name: 'ChipInAccount' }"
          class="text-light d-block font-weight-light"
          v-bind:class="{
            'text-muted': !artsit_data.chipin_enabled,
            disabled: !artsit_data.chipin_enabled,
          }"
          v-bind:disabled="!artsit_data.chipin_enabled"
        >
          Chipin Payment Overview
          <img
            v-if="artsit_data.chipin_enabled"
            src="../../assets/arrow-right.svg"
            class="float-right arrow"
            alt="Right arrow"
          />
          <img
            v-else
            src="../../assets/arrow-right.svg"
            class="float-right arrow ml-2"
            alt="Right arrow"
            style="filter: invert(0.45) !important;"
          />
        </router-link>
      </b-col>
    </b-row>

    <b-row class="mt-3 mt-xl-3 menu-text">
      <!-- Section Heading -->
      <b-col
        cols="12"
        class="text-uppercase text-light font-weight-light mt-3 mt-md-4 heading"
      >
        Artist Lineup & Reputation
        <hr class="bg-light" />
      </b-col>

      <!-- Section link -->
      <b-col cols="12" class="text-light font-weight-light">
        <router-link
          :to="{ name: 'AccountSettings' }"
          class="text-light d-block font-weight-light"
        >
          Account Level: {{ artsit_data.account_type }}

          <img
            src="../../assets/arrow-right.svg"
            class="float-right arrow ml-2"
            alt="Right arrow"
          />
          <span class="float-right" v-if="!isSupporter"><router-link variant="link" class="text-light" :to="{name: 'Pricing'}">Upgrade</router-link></span>
        </router-link>
        <hr class="bg-light" />
      </b-col>

      <!-- Section link
        <b-col cols="12" class="text-light font-weight-light">
          <router-link :to="{}" class="text-light d-block font-weight-light">
            Account Standing: Normal
            <img
              src="../../assets/arrow-right.svg"
              class="float-right arrow"
              alt="Right arrow"
            />
          </router-link>
          <hr class="bg-light" />
        </b-col> -->

      <!-- Section link -->
      <b-col cols="12" class="text-light font-weight-light">
        <router-link
          :to="{ name: 'AccountSettings' }"
          class="text-light d-block font-weight-light"
        >
          Show All Settings
          <img
            src="../../assets/arrow-right.svg"
            class="float-right arrow"
            alt="Right arrow"
          />
        </router-link>
      </b-col>
    </b-row>

  </b-col>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SettingsImageInput from "../../components/settings/image-input.vue";

export default {
  name: "DashboardMainMenu",
  props: ["photo", "supporter", "artsit_data"],
  data() {
    return {
      loaded: false,
      account_type: null,
      user_id: null,
      user_image: this.get_user_image,
      profile: {
        photo: this.get_user_image,
      },
    };
  },
  components: {
    SettingsImageInput,
  },
  computed: {
    ...mapGetters([
      "isChipInUser",
      "isSupporter",
      "username",
      "location",
      "get_user_image",
    ]),
    get_account_type() {
      if (!this.loaded) {
        return "";
      } else {
        if (this.account_type == "supporter") {
          return "Supporter";
        } else {
          return "Starter";
        }
      }
    },
  },
  mounted() {
    this.accountsettings().then((res) => {
      this.account_type = res.data.account_type;
      this.user_image = this.get_user_image;
      this.loaded = true;
    });
  },
  methods: {
    ...mapActions("Account", ["accountsettings"]),
    send_file(event, name, file) {
      file;

      var file_ = event.target.files[0];

      var filedata = new FormData();
      filedata.append(name, file_, file_.name);

      this.update_file(filedata)
        .then((res) => {
          this.storeProfileImage(res["photo"]);
          this.profile.photo = res["photo"];
        })
        .catch((error) => {
          error
        });
    },
  },
};
</script>

<style scoped>
.text-muted {
  font-size: 18px !important;
  line-height: auto !important;
}

.user-image {
  height: auto !important;
  width: auto !important;
}

.username {
  font-size: 24px;
  font-weight: 300;
}

.location {
  font-size: 18px;
  font-weight: 300;
}

.menu-text {
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}

.arrow {
  position: relative;
  top: -2px;
}

@media (min-width: 576px) {
  .user-image {
    height: 83px;
    width: 83px;
    transform: translate(30%, 20%);
  }

  .menu-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .user-image {
    height: 83px;
    width: 83px;
    transform: translate(0%, 15%);
  }

  .menu-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 992px) {
  .menu-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }
}

@media (min-width: 1200px) {
  .c-artist {
    justify-content: center;
    display: flex;
    justify-items: center;
    align-items: center;
  }
}
</style>
