<template>
  <b-col cols="12" class="">
    <b-row class="label-text">
      <!-- Section Heading -->
      <b-col
        cols="12"
        class="text-uppercase text-light font-weight-light mt-2 heading"
      >
        Artists Using Trackd
        <hr class="bg-light" />
      </b-col>

      <b-col
        v-for="(artist, index) in artists_using_trackd"
        :key="index"
        cols="4"
        class="px-0 text-center mt-md-3 mt-lg-3 mt-xl-2"
      >
        <router-link
          :to="{ name: 'ArtistUsername', params: { id: artist.username } }"
          class="text-light"
        >
          <b-img
            :src="artist.image"
            class="d-none d-lg-inline-block img-fluid artist-image mb-lg-2"
            rounded="circle"
          />
          <b-img
            :src="artist.image"
            class="d-lg-none img-fluid artist-image mb-2"
          />
          <p class="artist-name">{{ artist.name }}</p>
          <p class="band-name">{{ artist.band }}</p>
        </router-link>
      </b-col>
    </b-row>
    <!--
    <b-row>
      <b-col cols="12" class="text-light font-weight-light mt-2">
        <router-link :to="{}" class="text-light d-block font-weight-light">
          <p class="float-right more">
            More
            <img
              src="../../assets/arrow-right.svg"
              class="float-right arrow-other pl-1"
              alt="Right arrow"
            />
          </p>
        </router-link>
      </b-col>
    </b-row>
    -->
  </b-col>
</template>

<script>
export default {
  name: "ArtistsUsingTrackd",
  props: ["artists_using_trackd"],
};
</script>

<style scoped>
.artist-image {
  height: 80px;
  width: 80px;
}

.artist-name {
  font-size: 14px;
  font-weight: 200;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 0;
  text-align: center;
}

.band-name {
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  text-align: center;
}

.heading {
  font-size: 18px;
  font-weight: 300;
}

.more {
  font-size: 18px;
  font-weight: 300;
}

.arrow-other {
  position: relative;
  top: -2px;
}

@media (min-width: 576px) {
  .artist-name {
    font-size: 16px;
    font-weight: 200;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 0;
    text-align: center;
  }

  .band-name {
    font-size: 16px;
    font-weight: 900;
    line-height: 16px;
    text-align: center;
  }

  .heading {
    font-size: 18px;
    font-weight: 300;
  }
}

@media (min-width: 768px) {
  .artist-name {
    font-size: 16px;
    font-weight: 200;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 0;
    text-align: center;
  }

  .band-name {
    font-size: 16px;
    font-weight: 900;
    line-height: 16px;
    text-align: center;
  }

  .heading {
    font-size: 18px;
    font-weight: 300;
  }

  .more-arrow {
    transform: translateY(10%);
  }
}

@media (min-width: 992px) {
  .artist-image {
    height: 106px;
    width: 106px;
  }

  .artist-name {
    font-size: 16px;
    font-weight: 200;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 0;
    text-align: center;
  }

  .band-name {
    font-size: 16px;
    font-weight: 900;
    line-height: 16px;
    text-align: center;
  }

  .more-arrow {
    transform: none;
  }
}

@media (min-width: 1200px) {
  .artist-image {
    height: 106px;
    width: 106px;
  }

  .artist-name {
    font-size: 16px;
    font-weight: 200;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 0;
    text-align: center;
  }

  .band-name {
    font-size: 16px;
    font-weight: 900;
    line-height: 16px;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .arrow-other {
    top: 8px;
  }
}

@media (max-width: 768px) {
  .arrow-other {
    top: 3px;
  }
}
</style>
